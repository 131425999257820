const ApiUrl = process.env.NEXT_PUBLIC_API_URL;
const recipeApiUrl = process.env.NEXT_PUBLIC_RECIPE_API;
const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

//login
export const SEND_OTP_URL = UrlParamsReplace("/admins/send-otp");
export const SEND_PATIENT_OTP_URL = UrlParamsReplace("/login-with-phone");
export const VERIFY_OTP_URL = UrlParamsReplace("/admin/verify");
export const VERIFY_PATIENT_OTP_URL = UrlParamsReplace("/verify-otp-with-phone");
export const CREATE_USER_IN_FIREBASE = UrlParamsReplace("/firebase/user");
export const REFRESH_USER_TOKEN = UrlParamsReplace("/refresh-tokens");
export const LOGIN_WITH_FIREBASE = UrlParamsReplace("/login-with-firebase");
export const LOGOUT_USER = (userId) =>
  UrlParamsReplace("/users/:userId/token", { userId });

//patients
export const GET_ALL_PATIENTS = (offset, limit) =>
  UrlParamsReplace("/patients?limit=:limit&offset=:offset", { offset, limit });

export const GET_PATIENT_BY_ID = (id) => UrlParamsReplace("/patients/" + id);
export const UPDATE_PATIENT_BY_ID = (id) => UrlParamsReplace("/patients/" + id);

export const CREATE_PATIENT_RECIPE = () => UrlParamsReplace("/patient-recipes");

export const GET_NUTRITIONISTS_PATIENTS = (
  offset,
  limit,
  id,
  onlyFlareup,
  search,
  filters
) =>
  UrlParamsReplace(
    `/hc-nutritionists-patients/${id}?offset=:offset&limit=:limit${onlyFlareup ? "&onlyFlareup=" + onlyFlareup : ""
    }&search=:search&filters=:filters`,
    {
      offset,
      limit,
      search,
      filters,
    }
  );

export const GET_UV_COACH_FLAREUPS = (offset, limit, id, onlyFlareup) =>
  UrlParamsReplace(
    `/hc-uvcoach-flareups/${id}?offset=:offset&limit=:limit${onlyFlareup ? "&onlyFlareup=" + onlyFlareup : ""
    }`,
    { offset, limit }
  );

export const GET_CRM_PATIENTS_URL = (offset, limit, id, search, filters) =>
  UrlParamsReplace(
    `/crm-patients/${id}?offset=:offset&limit=:limit&search=:search&filters=:filters`,
    { offset, limit, id, search, filters }
  );

export const GET_PATIENT_RECIPE_BY_DATE = (id, mealDate) =>
  UrlParamsReplace(`/patient-recipe/${id}?mealDate=:mealDate`, { mealDate });

export const PATIENT_PILLAR_PHASE = () =>
  UrlParamsReplace(`/patient-pillar-phases`);

export const BULK_PATIENT_RECIPE = () =>
  UrlParamsReplace(`/bulk-patient-recipes`);

export const HC_SCHEDULE_CALL = () => UrlParamsReplace("/hc-schedule-call");

export const GET_DIETARY_PREFERNCE = (patientId) =>
  UrlParamsReplace("/dietary-preferences/" + patientId);
export const CREATE_DIETARY_PREFERNCE = (patientId) =>
  UrlParamsReplace(`/dietary-preferences`);

export const UPDATE_DIETARY_PREFERNCE = (id) =>
  UrlParamsReplace(`/dietary-preferences/${id}`);

export const UPDATE_HC_SCHEDULE_CALL = (id) =>
  UrlParamsReplace("/hc-schedule-call/:id", { id });

export const COPY_PATIENT_RECIPE = () =>
  UrlParamsReplace("/patient-recipes-copy");

export const GET_THOUGHT_EXERCISE = (patientId) =>
  UrlParamsReplace("/thoughtt-exercise/:patientId", { patientId });
export const GET_PATIENT_SURVEY_URL = (patientId) =>
  UrlParamsReplace("/patients/:patientId/surveys", { patientId });

export const UPDATE_PATIENT_ONBOARDING_ALERT = (patientId) =>
  UrlParamsReplace("/patients/:patientId/patient-onboarding-alert", {
    patientId,
  });
export const UPDATE_DOCTOR_MAPPING = (id) =>
  UrlParamsReplace(`/patients/map-doctor/${id}`);
export const GET_PATIENT_RECIPE_BY_DATE_RANGE = (id, startDate, endDate) =>
  UrlParamsReplace(
    `/patient-recipe-range?id=:id&startDate=:startDate&endDate=:endDate`,
    { id, startDate, endDate }
  );

export const UPDATE_USER_NUMBER = (id) => UrlParamsReplace('/users/:id/patient-number', { id });

//doctors
export const GET_ALL_DOCTORS = (offset, limit, search) =>
  UrlParamsReplace("/doctors?limit=:limit&offset=:offset&search=:search", {
    limit,
    offset,
    search,
  });

export const UPDATE_DOCTOR_URL = (doctorId) =>
  UrlParamsReplace("/doctors/:doctorId", { doctorId });

export const GET_DOCTORS_DATA = () => UrlParamsReplace("/doctors-data");

export const CREATE_DOCTOR = () => UrlParamsReplace("/doctors");

export const GET_DOCTOR_BY_ID = (doctorId) =>
  UrlParamsReplace("/doctors/" + doctorId);

export const GET_DOCTOR_QR_CODE = (doctorId) =>
  UrlParamsReplace("/doctors-qr-code/" + doctorId);

export const GET_ACTIVE_DOCTORS_QR_CODES = () =>
  UrlParamsReplace("/doctors-qr-code");

export const GET_PATIENTS_BY_DOCTOR = (offset, limit, name, doctorId) =>
  UrlParamsReplace(
    "/doctor-patients?limit=:limit&offset=:offset&name=:name&doctorId=:doctorId",
    { limit, offset, name, doctorId }
  );

export const GET_PATIENT_DETAILS_FOR_DOCTOR_BY_ID = (patientId) =>
  UrlParamsReplace("/doctor-patients/:patientId", { patientId });

//recipes
export const GET_RECIPES = ({ search, phase, diseaseId }) =>
  `${recipeApiUrl}/recipes?search=${search}&phase=${phase}&diseaseId=${diseaseId}`;
export const GET_RECIPE_BY_ID = (id) => `${recipeApiUrl}/recipes/+${id}`;
export const GET_RECIPE_BY_IDS = (ids, isDayFilterRequired) =>
  `${recipeApiUrl}/recipes-by-ids?ids=${ids?.map((id) => `${id}`)}&isDayFilterRequired=${isDayFilterRequired}`;
export const GET_RECIPE_BY_IDS_AND_DAYS = (ids, days) =>
  `${recipeApiUrl}/recipes-by-ids?ids=${ids?.map(
    (id) => `${id}`
  )}&days=${days.map((day) => `${day}`)}`;
export const PUBLISH_PATIENT_RECIPES = () =>
  UrlParamsReplace("/publish-patient-recipes");
export const UPDATE_PATIENT_RECIPES = () =>
  UrlParamsReplace("/patient-recipes");
export const OTHER_PATIENT_RECIPE = () =>
  UrlParamsReplace("/other-patient-recipes");

export const SEND_HC_OTP_URL = UrlParamsReplace("/admins/hc/send-otp");
export const CREATE_HC_USER = UrlParamsReplace("/admin/users");
export const ALL_ROLES = UrlParamsReplace("/roles");
export const ALL_ADMIN_USERS = (offset, limit, roleId, search, filters) =>
  UrlParamsReplace(
    "/hc-admin-users?offset=:offset&limit=:limit&roleId=:roleId&search=:search&filters=:filters",
    { offset, limit, roleId, search, filters }
  );
export const GET_ADMIN_USER_BY_ID = (id) =>
  UrlParamsReplace("/admin/users/:id", { id });
export const ALL_ADMIN_PATIENT = (offset, limit, search, filters) =>
  UrlParamsReplace(
    "/hc-admin-patients?offset=:offset&limit=:limit&search=:search&filters=:filters",
    { offset, limit, search, filters }
  );
export const ASSIGN_COACH_TO_PATIENT = (patientId) =>
  UrlParamsReplace("/admin/patient/hc/:patientId", { patientId });

// diet templates
export const GET_ALL_DIET_TEMPLATES = (offset, limit, diseaseId) =>
  UrlParamsReplace(
    "/diet-templates?limit=:limit&offset=:offset&diseaseId=:diseaseId",
    { limit, offset, diseaseId }
  );

export const CREATE_DIET_TEMPLATE = () => UrlParamsReplace("/diet-templates");
export const GET_PATIENT_EXERCISE_BY_DATE = (patientId, activityDate) =>
  UrlParamsReplace(
    `/hc-admin-patients-exercise-videos?patientId=:patientId&activityDate=:activityDate`,
    { patientId, activityDate }
  );

export const GET_DIET_TEMPLATES_BY_ID = (id) =>
  UrlParamsReplace("/diet-templates/:id", { id });

export const UPDATE_DIET_TEMPLATE = (dietTemplateId) =>
  UrlParamsReplace("/diet-templates/:dietTemplateId", { dietTemplateId });

export const GET_PATIENT_ADHERENCE_BY_ID = (id) =>
  UrlParamsReplace("/patient-all-recipe/" + id);

export const FLAREUP_QUESTIONS = (params) => {
  return UrlParamsReplace(`/flareup-questionnaire?diseaseId=:diseaseId`, params);
};
export const SUBMIT_FLAREUP_QUESTIONS = UrlParamsReplace(`/flareup-questionnaire`);
export const FLAREUP_RESULT_STATUS = UrlParamsReplace('/flareup-results/status');
export const UPDATE_FLAREUP_NOTE_URL = (id) => UrlParamsReplace('/flareup-results/:id', { id });
export const GET_IBS_SURVEYS_BY_PATIENT_ID = (patientId) => UrlParamsReplace('/ibs-survey/:patientId', { patientId });
export const GET_NERIVIO_SURVEYS_BY_PATIENT_ID = (patientId, weekNumber) => UrlParamsReplace('/migraine-survey/:patientId?week=:weekNumber', { patientId, weekNumber });
export const UPDATE_IBS_SURVEYS_RESULT_BY_PATIENT_ID = (patientId) => UrlParamsReplace('/ibs-survey/:patientId', { patientId });
export const NERIVIO_SURVEYS_RESULT_BY_PATIENT_ID = (diseaseId) => UrlParamsReplace('/migraine-survey?diseaseId=:diseaseId', { diseaseId });

export const GET_IBS_SURVEY_BY_ID = (id) =>
  UrlParamsReplace("/ibs-surveys/:id", { id });

export const GET_PATIENT_GOALS_BY_PATIENT_ID = (patientId) =>
  UrlParamsReplace("/patient-goals/:patientId", { patientId });

export const GET_UPLOAD_PRE_SIGED_URL = () =>
  UrlParamsReplace("/aws/get-upload-pre-signed-url");
export const UPLOAD_LAB_REPORT = (patientId) =>
  UrlParamsReplace("/admin/patient/lab-report/:patientId", { patientId });
export const GET_PERSONALIZATION_RESULT_BY_PATIENT_ID = (patientId) =>
  UrlParamsReplace("/personalization-result/:patientId", { patientId });
export const GET_PRE_SIGED_URL = (fileKey) =>
  UrlParamsReplace("/aws/get-pre-signed-url?fileKey=:fileKey", { fileKey });
export const BATCH_UPLOAD_PRE_SIGNED_URL = () =>
  UrlParamsReplace("/aws/get-batch-upload-pre-signed-url");
//push-notifications
export const GET_PUSH_NOTIFICATIONS_URL = () =>
  UrlParamsReplace("/push-notification");
export const GET_PUSH_NOTIFICATIONS_V1_URL = () =>
  UrlParamsReplace("/v1/push-notification");

export const HC_call = () => UrlParamsReplace("/hc-call");
//call
export const GET_ALL_CALLS = (offset, limit, callStatus) =>
  UrlParamsReplace(
    "/calls?limit=:limit&offset=:offset&callStatus=:callStatus",
    { offset, limit, callStatus }
  );

//slots
export const GET_ALL_SLOTS = UrlParamsReplace("/slots");

export const FLARE_UP_RESULT = (offset, patientId) =>
  UrlParamsReplace("/flareup-results?offset=:offset&patientId=:patientId", {
    offset,
    patientId,
  });

//notes
export const GET_NOTES_FOR_HC = ({ patientId, hcId, role = "" }) =>
  UrlParamsReplace("/call-notes?hcId=:hcId&patientId=:patientId&role=:role", {
    patientId,
    hcId,
    role,
  });
export const UPDATE_CALL_NOTES = (id) => UrlParamsReplace("/call-notes/" + id);
//personalization-journey
export const ADD_COMMENT_PERSONALIZATION_QUESTION = (id) =>
  UrlParamsReplace("/goals/questionnaire/:id", { id });

//diet plan generation
export const GET_DIET_PLAN_GENERATION_STATUS = (patientId, week, phase) =>
  UrlParamsReplace("/patient-diet-engine-queue/:patientId?week=:week&phase=:phase", { patientId, week, phase });
export const GET_DIET_PLAN_QUEUE_BY_ID = (id) =>
  UrlParamsReplace("/diet-engine-queue/:id", { id });
export const CREATE_DIET_ENGINE_QUEUE = () =>
  UrlParamsReplace("/diet-engine-queue");
export const UPDATE_DIET_ENGINE_QUEUE = (id) =>
  UrlParamsReplace("/diet-engine-queue/" + id);
export const GET_DIET_ENGINE_QUEUE = (id) =>
  UrlParamsReplace("/diet-engine-queue?hcId=:id", { id });
//patient food trigger
export const CREATE_PATIENT_FOOD_TRIGGER = () =>
  UrlParamsReplace("/patient-food-triggers");

//schedule manamgement
export const GET_USER_SCHEDULE_MANAGEMENT = (id, date) =>
  UrlParamsReplace("/users/:id/schedule-management?date=:date", { id, date });
export const GET_SCHEDULE_MANAGEMENT = ({
  userId,
  isCreatedByAdmin,
  offset,
  limit,
}) =>
  UrlParamsReplace(
    "/schedule-management?userId=:userId&isCreatedByAdmin=:isCreatedByAdmin&offset=:offset&limit=:limit",
    { userId, isCreatedByAdmin, offset, limit }
  );
export const CREATE_SCHEDULE_MANAGEMENT = () =>
  UrlParamsReplace("/schedule-management");
export const DELETE_SCHEDULE_MANAGEMENT = (id) =>
  UrlParamsReplace("/schedule-management/" + id);
//leave management
export const GET_LEAVE_MANAGEMENT = (offset, limit) =>
  UrlParamsReplace("/leave-management?offset=:offset&limit=:limit", {
    offset,
    limit,
  });
export const UPDATE_LEAVE_MANAGEMENT = (id) =>
  UrlParamsReplace("/leave-management/" + id);

//scheduled-calls
export const GET_SCHEDULED_CALL_BY_DATE = (scheduledDate, scheduledBy, type) =>
  UrlParamsReplace(
    "/scheduled-calls?scheduledDate=:scheduledDate&scheduledBy=:scheduledBy&type=:type",
    { scheduledDate, scheduledBy, type }
  );
//crm

export const UPDATE_CALL_STATUS = (id) =>
  UrlParamsReplace("/hc-call-status-update/:id", { id });
//patient-uv-sessions
export const GET_PATIENT_UV_SESSION_BY_REDNESS = ({ patientId, isRedness }) =>
  UrlParamsReplace(
    "/psoriasis/patients/:patientId/uv-sessions?isRedness=:isRedness",
    { patientId, isRedness }
  );
export const GET_PATIENT_UV_SESSIONS_BY_REDNESS_FOR_HC = ({
  hcId,
  offset,
  limit,
  isRedness,
}) =>
  UrlParamsReplace(
    "/psoriasis/hc/:hcId/patient-uv-sessions?offset=:offset&limit=:limit&isRedness=:isRedness",
    { hcId, offset, limit, isRedness }
  );
export const MARK_PATIENT_UV_SESSION_READ = () =>
  UrlParamsReplace("/psoriasis/patient-uv-sessions/status", {});
export const RESUME_PATIENT_UV_SESSION = ({ patientId, id }) =>
  UrlParamsReplace(
    "/psoriasis/patients/:patientId/patient-uv-session/:id/resume",
    { patientId, id }
  );
export const UPDATE_DISRUPTED_UV_SESSION = ({ id }) =>
  UrlParamsReplace("/psoriasis/patient-uv-sessions/:id/reason", { id });
//body parts
export const GET_BODY_PARTS = () => UrlParamsReplace("/body-parts");

//questionnare
export const GET_QUESTIONNAIRE = ({ diseaseId, scope }) =>
  UrlParamsReplace("/questionnaire/:diseaseId?scope=:scope", {
    diseaseId,
    scope,
  });
export const EDIT_PSORIASIS_QUESTIONNAIRE_URL = () =>
  UrlParamsReplace("/psoriasis/questionnaire");

export const SUBMIT_IBS_SURVEY_QUESTIONNAIRE = (diseaseId) => UrlParamsReplace('/questionnaire/:diseaseId', { diseaseId });


//psoriasis flareupssessionType
export const REPORT_PSORIASIS_FLAREUP = ({ patientId }) =>
  UrlParamsReplace("/psoriasis/patients/:patientId/flareups", { patientId });
export const GET_PSORIASIS_FLAREUP = ({ patientId, offset }) =>
  UrlParamsReplace("/psoriasis/patients/:patientId/flareups?offset=:offset", {
    patientId,
    offset,
  });

//psoriasis prescption
export const GENERATE_PATIENT_PRESCRIPTION = ({ patientId }) =>
  UrlParamsReplace("/psoriasis/patients/:patientId/patient-uv-session", {
    patientId,
  });
export const GET_PATIENT_PRESCRIPTION = ({
  sessionType,
  patientId,
  orderBy,
  sessionFilter,
  sort,
  offset,
  limit,
}) =>
  UrlParamsReplace(
    `/patient-all-uv-sessions?sessionType=:sessionType&patientId=:patientId&orderBy=:orderBy&sessionFilter=:sessionFilter&sort=:sort&offset=:offset&limit=:limit`,
    { sessionType, patientId, orderBy, sessionFilter, sort, offset, limit }
  );
export const GET_PATIENT_PRESCRIPTION_COUNT = ({ patientId }) =>
  UrlParamsReplace(`/patients/:patientId/uv-sessions/count`, { patientId });

//callback request
export const GET_CALLBACK_REQUEST_URL = ({ id, offset, limit, type }) =>
  UrlParamsReplace(
    "/hc/:id/callback-request?offset=:offset&limit=:limit&type=:type",
    { id, offset, limit, type }
  );
export const MARK_CALLBACK_REQUEST_AS_COMPLETED_URL = ({ scheduledCallId }) =>
  UrlParamsReplace("/callback-requests/:scheduledCallId/completed", {
    scheduledCallId,
  });

export const GET_PATIENT_EFFECTED_BODY_PARTS = ({ patientId }) =>
  UrlParamsReplace("/psoriasis/patients/:patientId/affected-body-parts", {
    patientId,
  });

//psoriasis orders
export const PSORIASIS_ALL_ORDERS = (offset, limit, search, filters) =>
  UrlParamsReplace(
    "/psoriasis/orders?offset=:offset&limit=:limit&search=:search&filters=:filters",
    { offset, limit, search, filters }
  );
//diet-challenge
export const GET_PATIENT_DIET_CHALLENGE = (patientId) =>
  UrlParamsReplace(
    "/psoriasis/patients/:patientId/patient-diet-challenge-progress",
    { patientId }
  );

//PROGRESS REPORT
export const GENERATE_PROGRESS_REPORT = () =>
  UrlParamsReplace("/psoriasis/progress-report/generate");
export const OFFBOARD_USER_URL = ({ userId }) =>
  UrlParamsReplace("/users/:userId/offboard", { userId });
//mood-summary
export const GET_PATIENT_MOOD_SUMMARY = (patientId) =>
  UrlParamsReplace("/psoriasis/patients/:patientId/mood-journal/adherence", {
    patientId,
  });
export const GET_PATIENT_WELLNESS = ({ patientId }) =>
  UrlParamsReplace("/patient-wellness-activity?patientId=:patientId", {
    patientId,
  });

//notification-count
export const GET_UNREAD_FLAREUP_COUNT = ({ hcId, status, forIbs }) =>
  UrlParamsReplace(
    "/psoriasis/flareup-count?hcId=:hcId&status=:status&forIbs=:forIbs",
    { hcId, status, forIbs }
  );
export const GET_UNREAD_REDNESS_COUNT = ({ hcId, status }) =>
  UrlParamsReplace("/psoriasis/redness-count?hcId=:hcId&status=:status", {
    hcId,
    status,
  });
export const GET_SCHEDULED_CALL_COUNT = ({ scheduledBy, callStatus }) =>
  UrlParamsReplace(
    "/psoriasis/scheduled-call-count?scheduledBy=:scheduledBy&callStatus=:callStatus",
    { scheduledBy, callStatus }
  );
export const GET_LEAVE_REQUEST_COUNT = ({ status }) =>
  UrlParamsReplace("/psoriasis/leave-request-count?status=:status", { status });
export const GET_PATIENT_COUNT = ({ type, hcId, status }) =>
  UrlParamsReplace(
    "/psoriasis/patients-count?type=:type&hcId=:hcId&status=:status",
    { type, hcId, status }
  );
export const UPDATE_PATIENT_COUNT = ({ patientId }) =>
  UrlParamsReplace("/psoriasis/patient/:patientId", { patientId });

//psoriasis-order
export const GET_ORDER_BY_LEAD_ID = (orderId) =>
  UrlParamsReplace("/orders/:orderId/lead", { orderId });
export const GET_LEAD_EXISTING_CONDITIONS_BY_ORDER_ID = (psoriasisOrderId) =>
  UrlParamsReplace("/orders/:psoriasisOrderId/exclusion-conditions", {
    psoriasisOrderId,
  });
export const UPDATE_LEAD_EXISTING_CONDITIONS_STATUS = (psoriasisOrderId) =>
  UrlParamsReplace("/orders/:psoriasisOrderId/exclusion-conditions", {
    psoriasisOrderId,
  });
export const DELETE_LEAD_EXISTING_CONDITIONS = (id) =>
  UrlParamsReplace("/exclusion-conditions/:id", { id });
export const GET_LEAD_PRESCRIPTIONS_BY_ORDER_ID = (psoriasisOrderId) =>
  UrlParamsReplace("/psoriasis/orders/:psoriasisOrderId/lead-prescriptions", {
    psoriasisOrderId,
  });
export const CREATE_BULK_LEAD_PRESCRIPTION_URL = ({ leadId }) =>
  UrlParamsReplace("/psoriasis/leads/:leadId/prescriptions", { leadId });
export const UPDATE_BULK_LEAD_PRESCRIPTION_URL = ({ psoriasisOrderId }) =>
  UrlParamsReplace("/psoriasis/leads/orders/:psoriasisOrderId/prescriptions", {
    psoriasisOrderId,
  });
export const UPDATE_LEAD_PRESCRIPTION_STATUS_URL = ({ psoriasisOrderId }) =>
  UrlParamsReplace("/psoriasis/orders/:psoriasisOrderId/lead-prescriptions", {
    psoriasisOrderId,
  });
export const UPDATE_LEAD_PRESCRIPTION = ({ id }) =>
  UrlParamsReplace("/psoriasis/lead-prescriptions/:id", { id });
export const GET_PSORIASIS_ORDER_DETAILS_URL = ({ id }) =>
  UrlParamsReplace("/psoriasis/orders/:id", { id });
export const CREATE_EVENT_URL = () => UrlParamsReplace("/psoriasis/event");
export const GET_DELIVERY_DETAILS_URL = (psoriasisOrderId) =>
  UrlParamsReplace("/orders/:psoriasisOrderId/delivery-details", {
    psoriasisOrderId,
  });
export const UPDATE_DELIVERY_DETAIL_URL = (psoriasisOrderId) =>
  UrlParamsReplace("/orders/:psoriasisOrderId/delivery-details", {
    psoriasisOrderId,
  });

//reports

export const GET_INGREDIENTS = ({ search, phase, diseaseId }) =>
  `${recipeApiUrl}/recipe-ingredients?search=${search}&phase=${phase}&diseaseId=${diseaseId}`;

export const GET_PSORIASIS_ORDER_REPORT = () =>
  UrlParamsReplace("/psoriasis/order-report");
export const GET_PSORIASIS_LEAD_DETAILS_REPORT = () =>
  UrlParamsReplace("/psoriasis/lead-details-report");

export const GET_HC_PATIENT_SCHEDULED_CALL = ({ id, offset, limit }) =>
  UrlParamsReplace("/patients/:id/calls?offset=:offset&limit=:limit", {
    id,
    offset,
    limit,
  });
export const GET_PATIENT_FOOD_TRIGGER = ({
  patientId,
  status = "assigned",
  startDate = "",
  endDate = "",
}) =>
  UrlParamsReplace(
    "/patient-food-triggers?patientId=:patientId&status=:status&startDate=:startDate&endDate=:endDate",
    { patientId, status, startDate, endDate }
  );

//onboarding channel  
export const DOWNLOAD_PATIENT_DETAILS_PDF = (id) => UrlParamsReplace("/patient-reports-pdf/:id", { id })
//onboarding channel
export const UPSERT_ONBOARDING_CHANNEL_URL = UrlParamsReplace(
  "/onboarding-channel",
  {}
);
export const DELETE_ONBOARDING_CHANNEL_URL = ({ channelId }) =>
  UrlParamsReplace("/onboarding-channel/:channelId", { channelId });
//psoriasis-order-link

export const CREATE_PSORIASIS_ORDER_LINK = () =>
  UrlParamsReplace("/psoriasis/pso-order-link");
export const UPDATE_PSORIASIS_ORDER_URL = ({ id }) =>
  UrlParamsReplace("/psoriasis/orders/:id", { id });

export const TRIGGER_FOODPATIENT_LISTING = (patientId, isDayFilterRequired) =>
  UrlParamsReplace(
    "/hc-admin-patients-recipies?patientId=:patientId&isDayFilterRequired=:isDayFilterRequired",
    { patientId, isDayFilterRequired }
  );
export const TRIGGER_FOOD_LISTING = ({
  patientId,
  startDate,
  endDate,
  status,
}) =>
  UrlParamsReplace(
    "/patient-food-triggers?patientId=:patientId&status=:status&=:startDate=:startDate&endDate=:endDate",
    { patientId, startDate, endDate, status }
  );
export const REMOVE_TRIGGER_FOOD = ({ id }) =>
  UrlParamsReplace("/remove-trigger-food?id=:id", { id });
export const GET_USER_BY_ID = (id) => UrlParamsReplace("/users/:id", { id });

//alert-notification
export const GET_ALERT_NOTIFICATION_URL = ({ type, hcId, isRead, offset, limit }) => UrlParamsReplace('/alert-notifications?type=:type&hcId=:hcId&isRead=:isRead&offset=:offset&limit=:limit', { type, hcId, isRead, offset, limit })
export const UPDATE_ALERT_NOTIFICATION_URL = (id) => UrlParamsReplace('/alert-notifications/:id', { id })
export const GET_ALERT_NOTIFICATION_TOTAL_COUNT = ({ hcId, role }) => UrlParamsReplace('/hc-alert-notifications/:hcId?role=:role', { hcId, role })